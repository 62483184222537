import { createStore } from "vuex";
import User from "./Modules/User";
import planning from "./Modules/Planning";
import movement from "./Modules/Movement";
import note from "./Modules/Note";
import statistic from "./Modules/Statistic";
import accounts from "./Modules/accounts";
import transfer from "./Modules/transfer";

export default createStore({
  state: {
    mainDate: new Date(),
  },
  mutations: {
    setMainDate(state, newDate) {
      state.mainDate = newDate;
    },
  },
  actions: {},
  modules: { User, planning, movement, note, statistic, accounts, transfer },
});
