import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import auth from "./middleware/auth";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
    meta: {
      title: "Register",
    },
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: () => import("../views/ForgetPassword.vue"),
    meta: {
      title: "Forget Password",
    },
  },
  {
    path: "/resetpass",
    name: "Reset Password",
    component: () => import("../views/ResetPass.vue"),
    meta: {
      title: "Reset Password",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    redirect: "/dashboard/movement",
    meta: {
      title: "Dashboard",
      middleware: [auth],
    },
    component: () => import("../views/Dashboard.vue"),
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "setting",
        name: "Setting",
        redirect: "/dashboard/setting/person",
        component: () => import("../views/Setting.vue"),
        children: [
          {
            path: "profile",
            name: "Profile",
            component: () => import("../views/Profile.vue"),
          },
          {
            path: "person",
            name: "Person",
            component: () => import("../views/Person.vue"),
          },
          {
            path: "initial-value",
            name: "Initial Value",
            component: () => import("../views/InitialValue.vue"),
          },
          {
            path: "categories",
            name: "Categories",
            component: () => import("../views/Categories.vue"),
          },
          {
            path: "accounts",
            name: "Accounts",
            component: () => import("../views/Accounts.vue"),
          },
        ],
      },
      {
        path: "planning",
        name: "Planning",
        component: () => import("../views/Planning.vue"),
      },
      {
        path: "transfer",
        name: "transfer",
        component: () => import("../views/MovementTransfer.vue"),
      },
      {
        path: "transferplanning",
        name: "transferplanning",
        component: () => import("../views/transferPlanning.vue"),
      },
      {
        path: "movement",
        name: "Movement",
        component: () => import("../views/Movement.vue"),
      },
      {
        path: "forecast",
        name: "forecast",
        component: () => import("../views/Forecast.vue"),
      },
      {
        path: "note",
        name: "Notes",
        component: () => import("../views/Note.vue"),
      },
      {
        path: "evaluation",
        name: "Evaluation",
        redirect: "/dashboard/evaluation/statistic",
        component: () => import("../views/Evaluation.vue"),
        children: [
          {
            path: "statistic",
            name: "Statistic",
            component: () => import("../views/Statistic.vue"),
          },
          {
            path: "actual-target",
            name: "Actual Target",
            component: () => import("../views/ActualTarget.vue"),
          },
          {
            path: "charts",
            name: "Charts",
            component: () => import("../views/Charts.vue"),
          },
        ],
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
