import axios from "axios";
const state = {
  noteList: [],
};
const mutations = {
  setNoteList(state, noteList) {
    state.noteList = noteList;
  },
};
const actions = {
  getNoteList({ commit }, data) {
    return axios
      .get("api/note", { params: { data } })
      .then((res) => {
        commit("setNoteList", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  RegisterNote({ dispatch }, data) {
    return axios
      .post("api/note", data)
      .then(() => {
        dispatch("getNoteList");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  UpdateNote({ dispatch }, data) {
    const id = data.id;
    delete data.id;
    return axios
      .patch("api/note/" + id, data)
      .then(() => {
        dispatch("getNoteList");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  DeleteNote({ dispatch }, data) {
    return axios
      .delete("api/note/" + data)
      .then(() => {
        dispatch("getNoteList");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
