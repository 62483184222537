import axios from "axios";
const state = {
  statisticList: [],
  actualTargetList: [],
  chartAll: [],
  chartIncomes: [],
  chartExpense: [],
  forecastList: [],
};
const mutations = {
  setStatisticList(state, statisticList) {
    let result = {
      subCategories: [],
    };

    Object.keys(statisticList).forEach((category) => {
      if (["incomes", "expenses", "saves"].indexOf(category) != -1) {
        if (!(category in result)) {
          result.subCategories[category] = [];
        }

        statisticList[category].items.map((item) => {
          if (!(item.parent_name in result.subCategories[category])) {
            result.subCategories[category][item.parent_name] = {
              items: [],
              total: 0,
              type: category == "incomes" ? "+" : "-",
            };
          }
          result.subCategories[category][item.parent_name].items.push(item);
          result.subCategories[category][item.parent_name].total += parseFloat(
            item.total_amount
          );
        });
      }
    });
    result["total"] = statisticList.total;
    result["beforeTotalSaves"] = statisticList.beforeTotalSaves;
    result["totalAccountsCredit"] = statisticList.totalAccountsCredit;
    result["beforeTotalAmount"] = statisticList.beforeTotalAmount;
    result["balancedTotalSaves"] = statisticList.balancedTotalSaves;
    state.statisticList = result;
  },

  setActualTargetList(state, actualTargetList) {
    let result = [];

    Object.keys(actualTargetList).forEach((item) => {
      if (!(item in result)) {
        result[item] = { totalMovement: 0, totalPlanning: 0, items: [] };
      }

      actualTargetList[item].map((i) => {
        if (!(i.parent_category in result[item].items)) {
          result[item].items[i.parent_category] = {
            totalMovement: 0,
            totalPlanning: 0,
            items: [],
          };
        }
        result[item].items[i.parent_category].items.push(i);
        result[item].items[i.parent_category].totalMovement += parseFloat(
          i.movement_amount
        );
        result[item].items[i.parent_category].totalPlanning += parseFloat(
          i.planning_amount
        );
        result[item].totalMovement += parseFloat(i.movement_amount);
        result[item].totalPlanning += parseFloat(i.planning_amount);
      });
    });

    state.actualTargetList = result;
  },
  setChartAll(state, chartAll) {
    state.chartAll = chartAll;
  },
  setChartIncomes(state, chartIncomes) {
    state.chartIncomes = chartIncomes;
  },
  setChartExpense(state, chartExpense) {
    state.chartExpense = chartExpense;
  },
  setForecastList(state, forecastList) {
    let maxPosNumber = [];
    let maxNegNumber = [];
    forecastList.months.map((item) => {
      if (Math.sign(item.total_amount) == 1) {
        maxPosNumber.push(item.total_amount);
      }
      if (Math.sign(item.total_amount) == -1) {
        maxNegNumber.push(Math.abs(item.total_amount));
      }
    });
    state.forecastList = forecastList;
    Object.assign(state.forecastList, {
      maxPosNumber: Math.max(...maxPosNumber),
      maxNegNumber: Math.max(...maxNegNumber),
    });
  },
};
const actions = {
  getStatisticList({ commit }, data) {
    return axios
      .get("api/statistic", { params: data })
      .then((res) => {
        commit("setStatisticList", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  getActualTargetList({ commit }, data) {
    return axios
      .get("api/actual-target", { params: data })
      .then((res) => {
        commit("setActualTargetList", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  getChartAll({ commit }, data) {
    return axios
      .get("api/grouped-statistics/" + data.type, {
        params: { from: data.from, to: data.to },
      })
      .then((res) => {
        commit("setChartAll", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  getChartIncomes({ commit }, data) {
    return axios
      .get("api/grouped-statistics/income", { params: data })
      .then((res) => {
        commit("setChartIncomes", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  getChartExpense({ commit }, data) {
    return axios
      .get("api/grouped-statistics/expense", { params: data })
      .then((res) => {
        commit("setChartExpense", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  getForecastData({ commit }, data) {
    // console.log(data);
    return axios.get("/api/monthly-statistic", { params: data }).then((res) => {
      commit("setForecastList", res.data);
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
