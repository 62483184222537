import axios from "axios";
const state = {
  planningList: [],
  effectivePlanningList: [],
};
const mutations = {
  setPlanningList(state, planningList) {
    let result = [];
    planningList.data.forEach((item) => {
      var nameCategory = "";
      switch (item.category.category_type_id) {
        case 1:
        case 2:
          nameCategory = "expenses";
          break;
        case 3:
          nameCategory = "income";
          break;
        case 4:
          nameCategory = "save";
          break;
      }
      if (!(nameCategory in result)) {
        result[nameCategory] = [];
      }
      if (!(item.category.parent.name in result[nameCategory])) {
        result[nameCategory][item.category.parent.name] = {
          total: 0,
          items: [],
        };
      }
      result[nameCategory][item.category.parent.name].items.push(item);
      result[nameCategory][item.category.parent.name].total +=
        item.now_total_amount;
    });
    state.planningList = result;
  },
  setEffectivePlanningListt(state, effectivePlanningList) {
    state.effectivePlanningList = effectivePlanningList;
  },
};
const actions = {
  getPlanningList({ commit }, data) {
    return axios
      .get("api/planning", { params: data })
      .then((res) => {
        commit("setPlanningList", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  getEffectivePlanningList({ commit }, data) {
    return axios
      .get("api/effective-plans", { params: data })
      .then((res) => {
        commit("setEffectivePlanningListt", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  setEffetivePaln({ dispatch }, data) {
    return axios
      .post("api/planning/" + data.id + "/do-effect", {
        date: data.date,
        amount: data.amount,
        account_id: data.account_id,
      })
      .then(() => {
        // dispatch("movement/getMovementList", null, { root: true });
        dispatch("getEffectivePlanningList", data.currentDate);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  RegisterPlan({ dispatch }, data) {
    return axios
      .post("api/planning", data.data)
      .then(() => {
        dispatch("getPlanningList", data.currentDate);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  UpdatePlan({ dispatch }, { info, currentDate }) {
    const id = info.id;
    delete info.id;
    return axios
      .patch("api/planning/" + id, info)
      .then(() => {
        dispatch("getPlanningList", currentDate);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  DeletePlan({ dispatch }, { id, currentDate }) {
    return axios
      .delete("api/planning/" + id)
      .then(() => {
        dispatch("getPlanningList", currentDate);
        // dispatch("getEffectivePlanningList",{from:currentDate.start , to:currentDate.end});
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
