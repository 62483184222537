<template>
  <div
    class="
      realtive
      min-h-screen
      flex flex-col
      justify-between
      items-center
      bg-gray-50
      py-12
      px-4
      sm:px-6
      lg:px-8
    "
  >
    <div class="max-w-md w-full space-y-8">
      <div>
        <img
          class="mx-auto h-16 w-auto"
          :src="$i18n.locale == 'de' ? '/img/logo.svg' : '/img/logoIt.svg'"
          alt="Workflow"
        />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {{ $t("Sign in to your account") }}
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          {{ $t("Or") }}
          {{ " " }}
          <router-link
            to="/register"
            class="font-medium text-fuchsia-600 hover:text-fuchsia-500"
          >
            {{ $t("Sign up here") }}
          </router-link>
        </p>
      </div>
      <form class="space-y-6" @submit.prevent="login">
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">
            {{ $t("Email address or Username") }}
          </label>
          <div class="mt-1">
            <input
              id="email"
              name="email"
              type="text"
              autocomplete="email"
              required
              v-model="user.email"
              class="
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none
                focus:ring-fuchsia-500
                focus:border-fuchsia-500
                sm:text-sm
              "
              @change="saveLogin = false"
            />
          </div>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium text-gray-700">
            {{ $t("Password") }}
          </label>
          <div class="mt-1">
            <input
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              v-model="user.password"
              class="
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none
                focus:ring-fuchsia-500
                focus:border-fuchsia-500
                sm:text-sm
              "
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input
              v-model="saveLogin"
              id="remember-me"
              name="remember-me"
              type="checkbox"
              class="
                h-4
                w-4
                text-fuchsia-600
                focus:ring-fuchsia-500
                border-gray-300
                rounded
              "
            />
            <label for="remember-me" class="ml-2 block text-sm text-gray-900">
              {{ $t("Remember me") }}
            </label>
          </div>

          <div class="text-sm">
            <router-link
              to="/forget-password"
              class="font-medium text-fuchsia-600 hover:text-fuchsia-500"
            >
              {{ $t("Forgot your password?") }}
            </router-link>
          </div>
        </div>
        <div class="w-full flex mb-3">
          <button
            @click.prevent="changeLan('de')"
            class="flex"
            :class="currentLan != 'de' ? 'filter grayscale' : 'underline'"
          >
            <span class="mr-1 text-sm">{{ $t("german") }}</span>
            <img class="h-6 w-6" src="../assets/img/germany.png" /></button
          ><button
            @click.prevent="changeLan('it')"
            class="flex ml-3"
            :class="currentLan != 'it' ? 'filter grayscale' : 'underline'"
          >
            <span class="mr-1 text-sm">{{ $t("italian") }}</span>
            <img class="h-6 w-6" src="../assets/img/italy.png" />
          </button>
        </div>
        <div>
          <button
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-fuchsia-600
              hover:bg-fuchsia-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-fuchsia-500
            "
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon
                class="h-5 w-5 text-fuchsia-500 group-hover:text-fuchsia-400"
                aria-hidden="true"
              />
            </span>
            {{ $t("Sign in") }}
          </button>
        </div>
      </form>
    </div>
    <Footer />
  </div>
</template>

<script>
import { LockClosedIcon } from "@heroicons/vue/solid";
import Footer from "../components/footer.vue";

export default {
  components: {
    LockClosedIcon,
    Footer,
  },
  data() {
    return {
      user: {
        email: null,
        password: null,
      },
      currentLan: "de",
      saveLogin: localStorage.getItem("saveLogin"),
    };
  },
  methods: {
    login() {
      this.$store.dispatch("User/login", this.user).then((res) => {
        this.$emit("showNotif", {
          type: res.type,
          msg: this.$t(res.msg),
          showNotif: true,
        });
        if (res.type == "success") {
          this.saveInfoLogin();
          this.$emit("close");
          this.$router.push("/dashboard");
        }
      });
    },
    changeLan(lan) {
      localStorage.setItem("haushaltsLang", lan);
      this.$i18n.locale = lan;
      this.currentLan = lan;
    },
    saveInfoLogin() {
      // this.saveLogin = !this.saveLogin;
      if (this.saveLogin == true) {
        localStorage.setItem("haushaltUser", this.user.email);
        localStorage.setItem("haushaltPass", this.user.password);
        localStorage.setItem("saveLogin", true);
      }
      if (this.saveLogin == false) {
        localStorage.removeItem("haushaltUser", this.user.email);
        localStorage.removeItem("haushaltPass", this.user.password);
        localStorage.setItem("saveLogin", false);
      }
      // console.log(this.saveLogin);
    },
  },
  mounted() {
    if (localStorage.getItem("haushaltsLang"))
      this.currentLan = localStorage.getItem("haushaltsLang");

    if (localStorage.getItem("saveLogin", true)) {
      this.user.email = localStorage.getItem("haushaltUser", this.user.email);
      this.user.password = localStorage.getItem(
        "haushaltPass",
        this.user.password
      );
    }
  },
};
</script>
