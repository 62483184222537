import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./tailwind.css";
import axios from "axios";
import mitt from "mitt";
import i18n from "./i18n";

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
axios.interceptors.request.use((config) => {
  let privateToken = localStorage.getItem("privateToken");
  if (privateToken) {
    config.headers["Authorization"] = `Bearer ${privateToken}`;
  }
  config.headers["Accept"] = "aplication/json";
  return config;
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    store,
  };
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  if (nearestWithTitle && nearestWithTitle.meta.title)
    document.title =
      i18n.global.t("Haushalten") + " | " + nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) {
    if (!to.meta.middleware) {
      return next();
    }
    return middleware[0]({
      ...context,
    });
  }
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));
  if (!to.meta.middleware) {
    next();
  }
  middleware[0]({
    ...context,
  });
});

const emitter = mitt();
const app = createApp(App).use(i18n);
app.config.globalProperties.emitter = emitter;
app.use(store).use(router).mount("#app");
