import axios from "axios";

const state = {
  transferList: [],
  effectiveTransferPlanningList: [],
  effectiveTransferList: [],
  transferDate: new Date(),
};

const mutations = {
  setTransferList(state, transferList) {
    state.transferList = transferList;
  },
  setEffectiveTransferPlanningList(state, effectiveTransferPlanningList) {
    state.effectiveTransferPlanningList = effectiveTransferPlanningList;
  },
  setEffectiveList(state, effectiveTransferList) {
    state.effectiveTransferList = effectiveTransferList;
  },
};

const actions = {
  getTransferList({ commit }, data) {
    return axios
      .get("api/transfer-planning", { params: data  })
      .then((res) => {
        commit("setTransferList", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  getEffectiveTransferPlanningList({ commit }, data) {
    return axios
      .get("api/effective-transfer-plans", { params: data })
      .then((res) => {
        commit("setEffectiveTransferPlanningList", res.data.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  registerTransfer({ dispatch }, data) {
    let currentDate = {
      start: `${data.date.slice(0, 4)}-01-01`,
      end: `${data.date.slice(0, 4)}-12-31`,
    };
    return axios
      .post("api/transfer-planning", data)
      .then(() => {
        dispatch("getTransferList", currentDate);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  deleteTransfer({ dispatch }, id) {
    let currentDate = {
      start: `${state.transferDate.getFullYear()}-01-01`,
      end: `${state.transferDate.getFullYear()}-12-31`,
    };
    return axios
      .delete("api/transfer-planning/" + id.id)
      .then(() => {
        dispatch("getTransferList", currentDate);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  updateTransfer({ dispatch }, { info }) {
    let currentDate = {
      start: `${state.transferDate.getFullYear()}-01-01`,
      end: `${state.transferDate.getFullYear()}-12-31`,
    };
    const id = info.id;

    return axios
      .patch("api/transfer-planning/" + id, info)
      .then(() => {
        dispatch("getTransferList", currentDate);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  getEffectiveTransferList({ commit }, {start, end,count,page}) {
    // console.log(start, end,count);
    return axios
      .get(`api/transfer`, { params: {start:start,end:end ,count,page} })
      .then((res) => {
        commit("setEffectiveList", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  doEffectTransferList({ dispatch }, data) {
    return axios
      .post("api/transfer-planning/" + data.id + "/do-effect", {
        date: data.date,
        amount: data.amount,
      })
      .then(() => {
        dispatch("getEffectiveTransferList", {
          start: data.currentDate.from,
          end: data.currentDate.to,
        });
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  deleteEffectiveTransfer(context, data) {
    return axios
      .delete("api/transfer/" + data)
      .then(() => {
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  registerEffectiveTransfer({ dispatch }, data) {
    let currentDate = {
      start: `${state.transferDate.getFullYear()}-01-01`,
      end: `${state.transferDate.getFullYear()}-12-31`,
    };
    return axios
      .post("api/transfer", data)
      .then(() => {
        dispatch("getEffectiveTransferList", currentDate);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  exportCv(context, data) {
    return axios
      .get("api/transfer-csv", { params: data })
      .then((response) => {
        var BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
        var fileURL = window.URL.createObjectURL(
          new Blob([BOM, response.data], {
            encoding: "UTF-8",
            type: "text/csv",
          })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.csv");
        document.body.appendChild(fileLink);
        fileLink.click();
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  updateEffectiveTransfer(context, data) {
    let id = data.info.id;
    delete data.info.id;
    return axios
      .patch("api/transfer/" + id, data.info)
      .then(() => {
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
