export default {
  "Sign in to your account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte melden Sie sich bei Ihrem Konto an"])},
  "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder"])},
  "Sign up here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier registrieren"])},
  "Email address or Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mailadresse oder Benutzername"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort"])},
  "Remember me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnern"])},
  "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort vergessen?"])},
  "Sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
  "new Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Konto"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "amount in €": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag in €"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
  "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
  "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist"])},
  "Intended to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soll"])},
  "difference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Differenz"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
  "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "Forgot password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort vergessen"])},
  "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
  "Manage initial values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startwerte verwalten"])},
  "By specifying the bank and cash balances in euros, the budget book corresponds to your real financial situation.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Angabe der Kassa- und Kontobestände in Euro entspricht das Haushaltsbuch Ihrer realen Finanzsituation."])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "new person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Person"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "This information will be displayed publicly so be careful what you share.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Informationen werden nicht öffentlich angezeigt."])},
  "Contry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provinz"])},
  "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alter"])},
  "People in your household (including yourself)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen in Ihrem Haushalt (inkl. Ihrer Person)"])},
  "Individuals with their own income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen mit eigenem Einkommen"])},
  "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinder"])},
  "Occupation of main earner (s)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beruf des/der Hauptverdienenden"])},
  "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
  "password setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort setzen"])},
  "you can change your password here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie Ihr Kennwort ändern"])},
  "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Kennwort"])},
  "New Password Confrimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Kennwort wiederholen"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ändern"])},
  "account deletation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil löschen"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["löschen"])},
  "Register a new account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren eines neuen Kontos"])},
  "Sign in here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier einloggen"])},
  "Confirm Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort bestätigen"])},
  "Reset your password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennwort zurücksetzen"])},
  "New Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Konto"])},
  "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit"])},
  "Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereich"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "New Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Bewegung"])},
  "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkung"])},
  "New Planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Planung"])},
  "Repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholung"])},
  "every": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle"])},
  "Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Anzahl"])},
  "Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungen"])},
  "Evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswertungen"])},
  "Planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planung Bewegungen"])},
  "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "Statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistik"])},
  "Actual Target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist-Soll-Vergleich"])},
  "Charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafiken"])},
  "Persons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen"])},
  "Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konten"])},
  "Initial Values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startwerte"])},
  "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgaben"])},
  "incomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen"])},
  "saves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklagen"])},
  "together": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinsam"])},
  "Number of repetitions per month or year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Wiederholungen pro Monat oder Jahr"])},
  "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen"])},
  "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährlich"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "User name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
  "Email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oder"])},
  "please fill email or username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte E-Mail oder Benutzername eingeben"])},
  "common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinsam"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifiziert"])},
  "not verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht verifiziert"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "email setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Einstellung"])},
  "you can change your email and confirm here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Ihre E-Mail ändern und hier bestätigen"])},
  "Advanced Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Suche"])},
  "Text in note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text in Bemerkung"])},
  "From Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Datum"])},
  "To Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis Datum"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
  "From account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Konto"])},
  "To account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Konto"])},
  "Due Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fälligkeit"])},
  "New transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Umbuchung"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl"])},
  "Move to effective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach effektive verschieben"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "Sum of annual expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe der jährlichen Ausgaben"])},
  "Annual budget for variable expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresbudget für variable Ausgaben"])},
  "Monthly budget for variable expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatsbudget für variable Ausgaben"])},
  "Sum of the annual income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe der jährlichen Einnahmen"])},
  "Start stand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfangsstand"])},
  "end result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endstand"])},
  "Total revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe Einnahmen"])},
  "Total expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe Ausgaben"])},
  "Total saves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe Rücklagen"])},
  "From reserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["davon Rücklagen"])},
  "Planned Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geplante Bewegungen"])},
  "Planned Transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geplante Umbuchungen"])},
  "Effective Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effektive Bewegungen"])},
  "Effective Transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effektive Umbuchungen"])},
  "Transfer Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbuchungen"])},
  "Transfer Planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planung Umbuchungen"])},
  "Update Planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planung aktualisieren"])},
  "Update Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegung aktualisieren"])},
  "Update transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umbuchung aktualisieren"])},
  "Filtered Effective Transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter AKTIV für effektive Bewegungen"])},
  "remove Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter zurücksetzen"])},
  "The email has already been taken.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese E-Mail-Adresse wird bereits verwendet."])},
  "The password confirmation does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kennwortbestätigung stimmt nicht überein."])},
  "The username has already been taken.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Benutzername ist bereits vergeben."])},
  "Invalid Credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername oder Kennwort ist falsch. Bitte überprüfen."])},
  "new planning of a transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Planung einer Umbuchung"])},
  "Withdraw from reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von Rücklage abbuchen"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "Sum of annual saves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe der jährlichen Rücklagen"])},
  "Amount less than reserve: delete reserve?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag kleiner als Rücklage: Rücklage löschen?"])},
  "Sum of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe der"])},
  "of which reserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["davon Rücklagen"])},
  "amount in Euro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag in Euro"])},
  "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "Months to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monate bis"])},
  "Years to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre bis"])},
  "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monate"])},
  "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre"])},
  "New planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Planung"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
  "successfully Logged in!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich angemeldet!"])},
  "successfully registerd!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich registriert!"])},
  "Einkommen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkommen"])},
  "Sonstige Erträge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige Erträge"])},
  "Sozialleistungen/Beiträge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozialleistungen/Beiträge"])},
  "Versicherungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versicherungen"])},
  "Geldanlagen/Altersvorsorge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldanlagen/Altersvorsorge"])},
  "Ratenrückzahlung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratenrückzahlung"])},
  "Kommunikation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommunikation"])},
  "Energie/Steuern/Gebühren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie/Steuern/Gebühren"])},
  "Wohnen/Zusatzkosten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnen/Zusatzkosten"])},
  "Lebensmittel/Getränke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebensmittel/Getränke"])},
  "Haushalt/Haushaltsausstattung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haushalt/Haushaltsausstattung"])},
  "Sonstiges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
  "Transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
  "Taschengelder/Spenden/Beiträge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taschengelder/Spenden/Beiträge"])},
  "Schule/Kindergarten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schule/Kindergarten"])},
  "Außer Haus Verpflegung/Getränke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Außer Haus Verpflegung/Getränke"])},
  "Hobby/Freizeit/Bildung/Urlaub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobby/Freizeit/Bildung/Urlaub"])},
  "Bekleidung/Schuhe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekleidung/Schuhe"])},
  "Körperpflege/Kosmetik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperpflege/Kosmetik"])},
  "Gesundheit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesundheit"])},
  "Rücklage Energie/Steuern/Gebühren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Energie/Steuern/Gebühren"])},
  "Rücklage Sonstiges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Sonstiges"])},
  "Rücklage Hobby/Freizeit/Bildung/Urlaub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Hobby/Freizeit/Bildung/Urlaub"])},
  "Rücklage Gesundheit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Gesundheit"])},
  "Rücklage Transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Transport"])},
  "Rücklage Haushalt/Haushaltsausstattung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Haushalt/Haushaltsausstattung"])},
  "Rücklage Geldanlagen/Altersvorsorge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Geldanlagen/Altersvorsorge"])},
  "Rücklage Wohnen/Zusatzkosten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Wohnen/Zusatzkosten"])},
  "Fixe Ausgaben": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixe Ausgaben"])},
  "Variable Ausgaben": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variable Ausgaben"])},
  "Rücklagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklagen"])},
  "Einnahmen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen"])},
  "Lohn netto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lohn netto"])},
  "Rente netto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rente netto"])},
  "Selbstständiges Einkommen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstständiges Einkommen"])},
  "Geschenke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschenke"])},
  "Erträge aus Geldanlagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erträge aus Geldanlagen"])},
  "Bankzinsen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankzinsen"])},
  "Kindergeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindergeld"])},
  "Pflegegeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflegegeld"])},
  "Mietenbeitrag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietenbeitrag"])},
  "Unterhalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterhalt"])},
  "Gebäudeversicherung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäudeversicherung"])},
  "Spar-Anlage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spar-Anlage"])},
  "Wertpapiere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertpapiere"])},
  "Ratenrückzahlung Auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratenrückzahlung Auto"])},
  "Ratenrückzahlung Haushaltsgeräte/Möbel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratenrückzahlung Haushaltsgeräte/Möbel"])},
  "Ratenrückzahlung Urlaub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratenrückzahlung Urlaub"])},
  "Ratenrückzahlung Sonstiges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratenrückzahlung Sonstiges"])},
  "Telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "Internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet"])},
  "Wasser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasser"])},
  "Müll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Müll"])},
  "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TV"])},
  "Gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
  "Strom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strom"])},
  "Heizen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heizen"])},
  "Wohnbaudarlehen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnbaudarlehen"])},
  "Miete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miete"])},
  "Lebensmittel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebensmittel"])},
  "Haustiere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haustiere"])},
  "Reinigung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinigung"])},
  "Geräte/Möbel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geräte/Möbel"])},
  "Öffentliche Verkehrsmittel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentliche Verkehrsmittel"])},
  "Auto/Treibstoffe/Reparaturen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto/Treibstoffe/Reparaturen"])},
  "Vereinsbeiträge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinsbeiträge"])},
  "Spenden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spenden"])},
  "Taschengeld Kinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taschengeld Kinder"])},
  "Ausflüge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausflüge"])},
  "Schulmaterialien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulmaterialien"])},
  "Mensa/Mittagessen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensa/Mittagessen"])},
  "Bar/Restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar/Restaurant"])},
  "Kurs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs"])},
  "Kino/Theater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kino/Theater"])},
  "Sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
  "Urlaub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urlaub"])},
  "Kleidung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleidung"])},
  "Schuhe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schuhe"])},
  "Friseur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frisör"])},
  "Kosmetika": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosmetika"])},
  "Arzt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arzt"])},
  "Medikamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medikamente"])},
  "Rücklage Heizen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Heizen"])},
  "Rücklage Urlaub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Urlaub"])},
  "Rücklage Geräte/Möbel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Geräte/Möbel"])},
  "Rücklage Wertpapiere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Wertpapiere"])},
  "Rücklage Haus/Wohnung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Haus/Wohnung"])},
  "Rücklage Auto/Treibstoffe/Reparaturen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklage Auto/Treibstoffe/Reparaturen"])},
  "Bewegung suchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegung suchen"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe"])},
  "Sum of incomes/expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summe der Ein/Ausgaben"])},
  "Showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige von"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse"])},
  "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([">"])},
  "Overviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersichten"])},
  "Attention: All data will be permanently deleted !!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung: Alle Daten werden UNWIDERRUFLICH GELÖSCHT!! Sind Sie wirklich sicher?"])},
  "Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorien"])},
  "Forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prognose"])},
  "Balance until today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo bis heute"])},
  "Forecast by the end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prognose bis Ende"])},
  "expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgaben"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles"])},
  "filter person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filterperson"])},
  "Schweiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schweiz"])},
  "Österreich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Österreich"])},
  "Deutschland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutschland"])},
  "Anders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])},
  "bis 18 Jahre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis 18 Jahre"])},
  "19 - 35 Jahre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19 - 35 Jahre"])},
  "36 - 65 Jahre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["36 - 65 Jahre"])},
  "über 65 Jahre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["über 65 Jahre"])},
  "5 and more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 und mehr"])},
  "3 and more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 und mehr"])},
  "Arbeiter/Arbeiterin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeiter:in"])},
  "Angestellte/r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angestellte:r"])},
  "Führungskraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führungskraft"])},
  "Selbstständige/r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstständige:r"])},
  "Unternehmer/in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmer:in"])},
  "Freiberufler/in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freiberufler:in"])},
  "Rentner/in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentner:in"])},
  "Anderer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])},
  "starting value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfangswert"])},
  "Haushalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haushalten"])},
  "Email has been successfully changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail wurde erfolgreich geändert"])},
  "what is new?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist neu?"])},
  "remind me later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Später erinnern"])},
  "understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe verstanden"])},
  "Important NOTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtiger Hinweis"])},
  "QUESTION_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie ist Voraussetzung, um Ihr Kennwort wiederherzustellen, sollten Sie es vergessen. Die E-Mailadresse wird nur für diesen Zweck verwendet und auf keinen Fall weitergegeben.<b> Wichtig</b>: Wenn Sie Ihre E-Mailadresse nicht angeben, kann das Konto im Fall eines Kennwortverlustes nicht wiederhergestellt werden und Sie müssen ein neues Konto anlegen, wobei Sie alle bisherigen Informationen unwiderbringlich verlieren."])},
  "Bitte geben Sie unter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie unter"])},
  "Ihre E-Mailadresse ein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mailadresse ein."])},
  "Ein Projekt von": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Projekt von"])},
  "copyRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realisiert mit den Geldern des Ministeriums für wirtschaftliche Entwicklung. Verteilung 2020"])},
  "Reset password link sent on your email id.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zum Zurücksetzen des Kennwortes, der mit Ihrer E-Mail-ID gesendet wurde."])},
  "Password has been successfully changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Kennwort wurde erfolgreich geändert"])},
  "User not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer wurde nicht gefunden"])},
  "bargeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bargeld"])},
  "Banksaldo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkonto"])},
  "show per page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pro Seite anzeigen"])}
}