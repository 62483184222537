import axios from "axios";

const state = {
  user: {},
  privateToken: null,
  persons: {},
  userPreference: [],
  categories: [],
  regions: [],
  provinces: [],
  zones: [],
};
const mutations = {
  authUser(state, privateToken) {
    state.privateToken = privateToken;
  },
  setUser(state, user) {
    state.user = user;
  },
  clearAuthData(state) {
    state.privateToken = null;
    window.localStorage.removeItem("privateToken");
    state.user = {};
  },
  setPrivateToken(state, privateToken) {
    state.privateToken = privateToken;
  },
  setPersons(state, persons) {
    state.persons = persons;
  },
  setUserPreference(state, userPreference) {
    state.userPreference = userPreference;
  },
  setCategories(state, categories) {
    state.categories = categories;
  },
  setRegions(state, regions) {
    state.regions = regions;
  },
  setProvinces(state, provinces) {
    state.provinces = provinces;
  },
  setZones(state, zones) {
    state.zones = zones;
  },
};
const actions = {
  login({ commit }, data) {
    return axios
      .post("api/login", data)
      .then((res) => {
        commit("setPrivateToken", res.data.access_token);
        window.localStorage.setItem("privateToken", res.data.access_token);
        commit("setUser", res.data.user);
        return {
          type: "success",
          msg: "successfully Logged in!",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  register({ commit }, data) {
    return axios
      .post("api/register", data)
      .then((res) => {
        commit("setPrivateToken", res.data.access_token);
        window.localStorage.setItem("privateToken", res.data.access_token);
        commit("setUser", res.data.user);
        return {
          type: "success",
          msg: "successfully registerd!",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            errors: error.response.data.errors,
          };
        }
      });
  },
  ForgetPassword(context, data) {
    return axios
      .post("api/forget-password", data)
      .then((res) => {
        return {
          type: "success",
          msg: res.data.message,
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  fetchUser({ commit }) {
    return axios
      .get("api/user")
      .then((res) => {
        commit("setUser", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  tryAutoLogin({ commit, dispatch }) {
    const privateToken = window.localStorage.getItem("privateToken")
      ? window.localStorage.getItem("privateToken")
      : null;
    if (!privateToken) {
      return;
    }
    commit("authUser", privateToken);
    dispatch("fetchUser");
  },
  deleteAccount({ commit }) {
    return axios
      .delete("api/delete-account")
      .then(() => {
        commit("clearAuthData");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  getPersons({ commit }, data) {
    const search = data ? { search: data } : null;
    return axios
      .get("api/person", { params: search })
      .then((res) => {
        commit("setPersons", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  RegisterPersons({ dispatch }, data) {
    return axios
      .post("api/person", data)
      .then(() => {
        dispatch("getPersons");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  DeletePersons({ dispatch }, data) {
    return axios
      .delete("api/person/" + data)
      .then(() => {
        dispatch("getPersons");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  UpdatePersons({ dispatch }, data) {
    return axios
      .patch("api/person/" + data.id, { name: data.name })
      .then(() => {
        dispatch("getPersons");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  GetUserPreference({ commit }) {
    return axios
      .get("api/user-preferences")
      .then((res) => {
        commit("setUserPreference", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  UpdateUserPreference({ dispatch }, data) {
    return axios
      .patch("api/user-preferences", data)
      .then(() => {
        dispatch("GetUserPreference");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  GetCategory({ commit }, data) {
    return axios
      .get("api/category", { params: data })
      .then((res) => {
        // console.log(res);
        commit("setCategories", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  RegisterCategory({ dispatch }, data) {
    return axios
      .post("api/category", data)
      .then(() => {
        dispatch("GetCategory");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  setActiveCategory({ dispatch }, data) {
    return axios
      .patch("api/category/" + data.id + "/" + data.active)
      .then(() => {
        dispatch("GetCategory");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  deleteCategory({ dispatch }, data) {
    return axios
      .delete("api/category/" + data.id)
      .then(() => {
        dispatch("GetCategory");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  updateCategory({ dispatch }, data) {
    return axios
      .patch("api/category/" + data.id)
      .then(() => {
        dispatch("GetCategory");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  getRegions({ commit }) {
    return axios
      .get("api/regions")
      .then((res) => {
        commit("setRegions", res.data.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  updateProfile({ dispatch }, data) {
    return axios
      .patch("api/update-profile", data)
      .then(() => {
        dispatch("fetchUser");
        return {
          type: "success",
          msg: "your profile has been successfully updated!",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  DeleteCategory({ dispatch }, data) {
    return axios
      .delete("api/category/" + data)
      .then(() => {
        dispatch("GetCategory");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  UpdateCategory({ dispatch }, data) {
    return axios
      .patch("api/category/" + data.id, {
        name: data.name,
        region_id: data.region_id,
        parent_id: data.parent_id,
      })
      .then(() => {
        dispatch("GetCategory");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  GetProvinces({ commit }) {
    return axios
      .get("api/provinces")
      .then((res) => {
        commit("setProvinces", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  GetZones({ commit }) {
    return axios
      .get("api/zones")
      .then((res) => {
        commit("setZones", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  changePassword({ dispatch }, data) {
    return axios
      .patch("api/change-password", data)
      .then(() => {
        dispatch("fetchUser");
        return {
          type: "success",
          msg: "your password has been changed successfully",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  resetPassword(context, data) {
    return axios
      .post("api/reset-password", data)
      .then((res) => {
        return {
          type: "success",
          msg: res.data.message,
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  confirmEmail({ dispatch }, { email, language }) {
    return axios
      .patch("api/change-email", { email, language })
      .then((res) => {
        console.log(res);
        dispatch("fetchUser");
        return {
          type: "success",
          msg: res.data.message,
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
