<template>
  <div>
    <router-view @showNotif="data = $event" />
  </div>

  <Notification
    :show="data.showNotif"
    @hide="data.showNotif = false"
    :type="data.type"
    :msg="data.msg"
  />
</template>
<script>
import Notification from "./components/Notification.vue";
export default {
  components: { Notification },
  data() {
    return {
      data: {},
      registration: null,
    };
  },
  methods: {
    updateAvailable(event) {
      this.registration = event.detail;
      // // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload();
    },
  },
  created() {
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
    this.emitter.on("showNotif", (data) => {
      this.data = data;
    });
    if (localStorage.getItem("privateToken"))
      this.$store.dispatch("User/tryAutoLogin");
    localStorage.getItem("haushaltsLang")
      ? (this.$i18n.locale = localStorage.getItem("haushaltsLang"))
      : (this.$i18n.locale = "de");
  },
};
</script>
<style>
@import "../node_modules/animate.css/animate.min.css";
@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  /* background: #feb911; */
  background: #c026d3;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c026d3;
  border-radius: 10px;
}
body {
  font-family: system-ui, -apple-system,
    /* Firefox supports this but not yet `system-ui` */ "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}
</style>
