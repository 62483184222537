export default {
  "Sign in to your account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to your account"])},
  "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odar"])},
  "Sign up here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up here"])},
  "Email address or Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address or Username"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Remember me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
  "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
  "Sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "new Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new Account"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
  "amount in €": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount in €"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
  "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is"])},
  "Intended to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intended to"])},
  "difference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["difference"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new"])},
  "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "Forgot password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
  "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "Manage initial values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage initial values"])},
  "By specifying the bank and cash balances in euros, the budget book corresponds to your real financial situation.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By specifying the bank and cash balances in euros, the budget book corresponds to your real financial situation."])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "new person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new person"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "This information will be displayed publicly so be careful what you share.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This information will be displayed publicly so be careful what you share."])},
  "Contry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contry"])},
  "Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
  "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["age"])},
  "People in your household (including yourself)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People in your household (including yourself)"])},
  "Individuals with their own income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuals with their own income"])},
  "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["children"])},
  "Occupation of main earner (s)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupation of main earner (s)"])},
  "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password setting"])},
  "you can change your password here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you can change your password here"])},
  "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "New Password Confrimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password Confrimation"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["change"])},
  "account deletation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account deletation"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
  "Register a new account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register a new account"])},
  "Sign in here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in here"])},
  "Confirm Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "Reset your password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])},
  "New Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Account"])},
  "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["credit"])},
  "Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "New Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Movement"])},
  "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comment"])},
  "New Planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Planning"])},
  "Repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetition"])},
  "every": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["every"])},
  "Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movement"])},
  "Evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
  "Planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning"])},
  "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setting"])},
  "Statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistic"])},
  "Actual Target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual Target"])},
  "Charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charts"])},
  "Persons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persons"])},
  "Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts"])},
  "Initial Values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial Values"])},
  "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yearly"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["monthly"])},
  "together": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["together"])},
  "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expenses"])},
  "incomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incomes"])},
  "saves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saves"])},
  "Number of repetitions per month or year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of repetitions per month or year"])},
  "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["income"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "User name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])},
  "Email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or"])},
  "please fill email or username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please fill email or username"])},
  "common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["common"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verified"])},
  "not verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not verified"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
  "email setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email setting"])},
  "you can change your email and confirm here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you can change your email and confirm here"])},
  "Advanced Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced Search"])},
  "Text in note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text in Note"])},
  "From Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Date"])},
  "To Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Date"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "From account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From account"])},
  "To account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To account"])},
  "Due Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
  "planned movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["planned movement"])},
  "New transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New transfer"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select"])},
  "Effective Transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective Transfer"])},
  "Move to effective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move to effective"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "Sum of annual expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of annual expenses"])},
  "Annual budget for variable expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual budget for variable expenses"])},
  "Monthly budget for variable expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly budget for variable expenses"])},
  "Sum of the annual income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of the annual income"])},
  "Start stand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start stand"])},
  "end result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end result"])},
  "Effective Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective Movement"])},
  "Total revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total revenue"])},
  "Total expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total expenses"])},
  "Total saves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total saves"])},
  "From reserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From reserves"])},
  "Planned Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planned Movement"])},
  "Transfer Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Movement"])},
  "Transfer Planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer Planning"])},
  "Update Planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Planning"])},
  "Update Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Movement"])},
  "Update transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update transfer"])},
  "Filtered Effective Transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtered Effective Transfers"])},
  "remove Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove Filter"])},
  "The email has already been taken.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email has already been taken."])},
  "The password confirmation does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password confirmation does not match."])},
  "The username has already been taken.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The username has already been taken."])},
  "Invalid Credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please check username or password"])},
  "new planning of a transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["new planning of a transfer"])},
  "Withdraw from reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw from reserve"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "Sum of annual saves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of annual saves"])},
  "Amount less than reserve: delete reserve?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount less than reserve: delete reserve?"])},
  "Sum of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of"])},
  "of which reserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of which reserves"])},
  "amount in Euro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amount in Euro"])},
  "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "Months to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Months to"])},
  "Years to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years to"])},
  "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Months"])},
  "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])},
  "New planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New planning"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
  "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
  "successfully Logged in!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successfully Logged in!"])},
  "successfully registerd!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successfully registered!"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "Sum of incomes/expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of incomes/expenses"])},
  "Showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
  "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "Overviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overviews"])},
  "Attention: All data will be permanently deleted !!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention: All data will be permanently deleted !!"])},
  "Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
  "Forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecast"])},
  "Balance until today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance until today"])},
  "Forecast by the end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecast by the end"])},
  "expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expense"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "filter person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filter person"])},
  "starting value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["starting value"])},
  "Haushalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haushalten"])},
  "Email has been successfully changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email has been successfully changed"])},
  "what is new?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["what is new?"])},
  "remind me later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remind me later"])},
  "show per page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show per page"])}
}