<template>
  <div
    class="
      flex flex-col
      justify-center
      sm:flex-row sm:justify-between
      border-secondary border-t-0
      sm:border-t-2
      pt-2
      text-gray-500
      font-semibold
      mt-auto
    "
  >
    <div
      class="
        order-2
        sm:order-1
        text-center
        mt-2
        sm:mt-0 sm:text-left
        border-secondary border-t-2
        sm:border-t-0
      "
    >
      <p>
        {{ $t("copyRight") }}
      </p>
    </div>
    <div class="flex items-end space-x-4 order-1 sm:order-2 justify-center">
      <p class="mb-3">{{ $t("Ein Projekt von") }}</p>
      <a class="" href="https://www.consumer.bz.it" target="_blank">
        <img
          class="w-44 sm:w-64"
          src="../assets/img/footer.webp"
          alt="footer"
        />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "footer",
  data() {
    return {};
  },
};
</script>
