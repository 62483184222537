import axios from "axios";
const state = {
  accountList: [],
  accountOverview: [],
};
const mutations = {
  setAccountList(state, accountList) {
    state.accountList = accountList;
  },
  setAccountOverview(state, accountOverview) {
    state.accountOverview = accountOverview;
  },
};
const actions = {
  getAccountList({ commit }, {count=10 , page=1}= {}) {
    return axios
      .get("api/account", { params: { count,page } })
      .then((res) => {
        commit("setAccountList", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  RegisterAccount({ dispatch }, data) {
    return axios
      .post("api/account", data)
      .then(() => {
        dispatch("getAccountList");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  UpdateAccount({ dispatch }, data) {
    const id = data.id;
    delete data.id;
    return axios
      .patch("api/account/" + id, data)
      .then(() => {
        dispatch("getAccountList");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  DeleteAccount({ dispatch }, data) {
    return axios
      .delete("api/account/" + data)
      .then(() => {
        dispatch("getAccountList");
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  getAccountOverview({ commit }, data) {
    return axios
      .get("/api/account/overview", { params: data })
      .then((res) => {
        commit("setAccountOverview", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
