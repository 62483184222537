import axios from "axios";
const state = {
  movementList: [],
  movementData: [],
  savesList: [],
  searchFilters:{}
};
const mutations = {
  setMovementList(state, movementList) {
    state.movementList = movementList;
  },
  setMovementData(state, movementData) {
    state.movementData = movementData;
  },
  setSavesList(state, savesList) {
    state.savesList = savesList;
  },
  setSearchFilters(state,searchFilters){
    state.searchFilters = searchFilters;
  },
  resetSearchFilters(state){
    state.searchFilters = {};
  }
};
const actions = {
  getMovementList({ commit }, data) {
    return axios
      .get("api/movement", { params: data })
      .then((res) => {
        commit("setMovementList", res.data.movements);
        commit("setMovementData", res.data);
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  exportCv(context, data) {
    return axios
      .get("api/movement-csv", { params: data })
      .then((response) => {
        var BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
        var fileURL = window.URL.createObjectURL(
          new Blob([BOM, response.data], {
            encoding: "UTF-8",
            type: "text/csv",
          })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.csv");
        document.body.appendChild(fileLink);
        fileLink.click();
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  RegisterMovement(context, data) {
    return axios
      .post("api/movement", data)
      .then(() => {
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  UpdateMovement(context, data) {
    delete data.category;
    delete data.clear_account;
    delete data.parent;
    delete data.person;
    const id = data.id;
    delete data.id;
    return axios
      .patch("api/movement/" + id, data)
      .then(() => {
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  DeleteMovement(context, data) {
    return axios
      .delete("api/movement/" + data)
      .then(() => {
        return {
          type: "success",
          msg: "ok",
        };
      })
      .catch((error) => {
        if (error.response) {
          return {
            type: "error",
            msg: error.response.data.message,
          };
        }
      });
  },
  getValidSavesList(context, data) {
    return axios.get("api/not-clear-saves/", { params: data }).then((res) => {
      // commit("setSavesList", res.data);
      return res.data;
    });
  },
  setSearchFilters({ commit },data){
    commit('setSearchFilters',data)
  },
  restSearchFilters({ commit }){
    commit('resetSearchFilters')
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
