export default {
  "Sign in to your account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, effettui l'accesso al suo account"])},
  "Or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
  "Sign up here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrare qui"])},
  "Email address or Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo e-mail o nome utente"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "Remember me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricordami"])},
  "Forgot your password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimenticato la password?"])},
  "Sign in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettuare login"])},
  "new Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo account"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "amount in €": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo in €"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azione"])},
  "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona"])},
  "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
  "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettivo"])},
  "Intended to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obiettivo"])},
  "difference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Differenza"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivo"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea"])},
  "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
  "Forgot password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password dimenticata"])},
  "Send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
  "Manage initial values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amministra valori iniziali"])},
  "By specifying the bank and cash balances in euros, the budget book corresponds to your real financial situation.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicando i saldi di cassa e di conto in euro, il libro del bilancio corrisponde alla sua reale situazione finanziaria."])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
  "new person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova persona"])},
  "Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
  "This information will be displayed publicly so be careful what you share.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queste informazioni non vengono visualizzate pubblicamente."])},
  "Contry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese"])},
  "Province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
  "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Età"])},
  "People in your household (including yourself)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persone nella sua famiglia (incluso lei)"])},
  "Individuals with their own income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persone con reddito proprio"])},
  "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bambini"])},
  "Occupation of main earner (s)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupazione della persona che guadagna di più"])},
  "Username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome utente"])},
  "password setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazione password"])},
  "you can change your password here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui può cambiare la sua password"])},
  "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password nuova"])},
  "New Password Confrimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripetere password nuova"])},
  "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modifica"])},
  "account deletation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina profilo"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elimina"])},
  "Register a new account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creazione di un nuovo account"])},
  "Sign in here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fare login qui"])},
  "Confirm Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare password"])},
  "Reset your password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristinare la password"])},
  "New Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo account"])},
  "credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credito"])},
  "Area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settore"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
  "New Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo movimento"])},
  "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
  "Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto"])},
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annotazione"])},
  "New Planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova pianficazione"])},
  "Repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripetizioni"])},
  "every": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tutti"])},
  "Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Numero"])},
  "Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimenti"])},
  "Evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisi"])},
  "Planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pianificazione movimenti"])},
  "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
  "Statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiche"])},
  "Actual Target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confronto effettivo-obiettivo"])},
  "Charts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafici"])},
  "Persons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persone"])},
  "Accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conti"])},
  "Initial Values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valori iniziali"])},
  "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese"])},
  "incomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrate"])},
  "saves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserve"])},
  "together": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comune"])},
  "Number of repetitions per month or year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di ripetizioni per mese o anno"])},
  "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrate"])},
  "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuale"])},
  "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensile"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "Sign up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedere"])},
  "User name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome utente"])},
  "Email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo e-mail"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
  "please fill email or username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore inserire nome utente oppure indirizzo e-mail"])},
  "common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comune"])},
  "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificato"])},
  "not verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non verificato"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermare"])},
  "email setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni e-mail"])},
  "you can change your email and confirm here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Può cambiare la sua email e confermare qui"])},
  "Advanced Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca estesa"])},
  "Text in note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testo in commento"])},
  "From Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da data"])},
  "To Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
  "From account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dal conto"])},
  "To account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al conto"])},
  "Due Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scadenza"])},
  "New transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo trasferimento"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scelta"])},
  "Move to effective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muovi in effettivi"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esportazione"])},
  "Sum of annual expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesa annuale totale"])},
  "Annual budget for variable expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget annuale per spese variabili"])},
  "Monthly budget for variable expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget mensile per spese variabili"])},
  "Sum of the annual income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrata annuale totale"])},
  "Start stand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo iniziale"])},
  "end result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo finale"])},
  "Total revenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale entrate"])},
  "Total expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale spese"])},
  "Total saves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale riserve"])},
  "From reserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di cui riserve"])},
  "Planned Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimenti pianificati"])},
  "Planned Transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimenti pianificati"])},
  "Effective Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movimenti effettivi"])},
  "Effective Transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimenti effetivi"])},
  "Transfer Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimenti"])},
  "Transfer Planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pianificazione trasferimenti"])},
  "Update Planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica pianificazione"])},
  "Update Movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica movimento"])},
  "Update transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica trasferimento"])},
  "Filtered Effective Transfers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro ATTIVO per movimenti effettivi"])},
  "remove Filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azzera il filtro"])},
  "The email has already been taken.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'indirizzo email è già in uso."])},
  "The password confirmation does not match.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La conferma della password non corrisponde."])},
  "The username has already been taken.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome utente è già utilizzato."])},
  "Invalid Credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome utente o la password non sono corretti. Per favore controlli."])},
  "new planning of a transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova pianficazione di un trasferimento"])},
  "Withdraw from reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addebito dalla riserva"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
  "Sum of annual saves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale delle riserve annuali"])},
  "Amount less than reserve: delete reserve?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo inferiore alla riserva: Azzerare la riserva?"])},
  "Sum of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma di"])},
  "of which reserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di cui riserve"])},
  "amount in Euro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo in Euro"])},
  "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti"])},
  "Months to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesi fino al"])},
  "Years to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anni fino al"])},
  "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesi"])},
  "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anni"])},
  "New planning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova pianficazione"])},
  "german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
  "italian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italiano"])},
  "successfully Logged in!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettuato l'accesso con successo!"])},
  "successfully registerd!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrato con successo!"])},
  "Einkommen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddito"])},
  "Sonstige Erträge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri proventi"])},
  "Sozialleistungen/Beiträge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prestazioni/contributi sociali"])},
  "Versicherungen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione"])},
  "Geldanlagen/Altersvorsorge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investimenti/previdenza"])},
  "Ratenrückzahlung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimborso rateale"])},
  "Kommunikation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunicazione"])},
  "Energie/Steuern/Gebühren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energia/tasse"])},
  "Wohnen/Zusatzkosten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alloggio/costi aggiuntivi"])},
  "Lebensmittel/Getränke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentari e bevande"])},
  "Haushalt/Haushaltsausstattung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attrezzatura per la casa"])},
  "Sonstiges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varie"])},
  "Transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasporto"])},
  "Taschengelder/Spenden/Beiträge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paghetta/Donazioni/Contributi"])},
  "Schule/Kindergarten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scuola/Asilo"])},
  "Außer Haus Verpflegung/Getränke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catering fuori casa/bevande"])},
  "Hobby/Freizeit/Bildung/Urlaub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hobby/Tempo libero/Istruzione/Vacanze"])},
  "Bekleidung/Schuhe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbigliamento/Scarpe"])},
  "Körperpflege/Kosmetik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cura della persona/Cosmetici"])},
  "Gesundheit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salute"])},
  "Rücklage Energie/Steuern/Gebühren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva energia/Tasse"])},
  "Rücklage Sonstiges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altra riserva"])},
  "Rücklage Hobby/Freizeit/Bildung/Urlaub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva hobby/Tempo libero/Istruzione/Vacanze"])},
  "Rücklage Gesundheit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva salute"])},
  "Rücklage Transport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva trasporto"])},
  "Rücklage Haushalt/Haushaltsausstattung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva per attrezzature per la casa"])},
  "Rücklage Geldanlagen/Altersvorsorge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva per investimenti finanziari/previdenza per la vecchiaia"])},
  "Rücklage Wohnen/Zusatzkosten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva per alloggio/costi aggiuntivi"])},
  "Fixe Ausgaben": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese fisse"])},
  "Variable Ausgaben": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese variabili"])},
  "Rücklagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserve"])},
  "Einnahmen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddito"])},
  "Lohn netto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stipendio netto"])},
  "Rente netto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensione netta"])},
  "Selbstständiges Einkommen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddito indipendente"])},
  "Geschenke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regali"])},
  "Erträge aus Geldanlagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reddito da investimenti"])},
  "Bankzinsen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interessi bancari"])},
  "Kindergeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegni familiari"])},
  "Pflegegeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegno di cura"])},
  "Mietenbeitrag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contributo affitto"])},
  "Unterhalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimenti"])},
  "Gebäudeversicherung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assicurazione edilizia"])},
  "Spar-Anlage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposito di risparmio"])},
  "Wertpapiere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titoli"])},
  "Ratenrückzahlung Auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimborso rateale auto"])},
  "Ratenrückzahlung Haushaltsgeräte/Möbel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimborso rateale elettrodomestici/mobili"])},
  "Ratenrückzahlung Urlaub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimborso rateale ferie"])},
  "Ratenrückzahlung Sonstiges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimborso rateale altro"])},
  "Telefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
  "Internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet"])},
  "Wasser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acqua"])},
  "Müll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifuiti"])},
  "TV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TV"])},
  "Gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
  "Strom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrente"])},
  "Heizen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riscaldamento"])},
  "Wohnbaudarlehen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutuo per la casa"])},
  "Miete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitto"])},
  "Lebensmittel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentari"])},
  "Haustiere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animali domestici"])},
  "Reinigung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pulizia"])},
  "Geräte/Möbel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attrezzature/mobili"])},
  "Öffentliche Verkehrsmittel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasporto pubblico"])},
  "Auto/Treibstoffe/Reparaturen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto/carburanti/riparazioni"])},
  "Vereinsbeiträge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasse associative"])},
  "Spenden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donazioni"])},
  "Taschengeld Kinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paghetta bambini"])},
  "Ausflüge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escursioni"])},
  "Schulmaterialien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiale scolastico"])},
  "Mensa/Mittagessen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caffetteria/pranzo"])},
  "Bar/Restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar/ristorante"])},
  "Kurs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corso"])},
  "Kino/Theater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cinema/teatro"])},
  "Sport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport"])},
  "Urlaub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vacanza"])},
  "Kleidung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbigliamento"])},
  "Schuhe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarpe"])},
  "Friseur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parrucchiere"])},
  "Kosmetika": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosmetici"])},
  "Arzt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medico"])},
  "Medikamente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farmaco"])},
  "Rücklage Heizen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva riscaldamento"])},
  "Rücklage Urlaub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva vacanza"])},
  "Rücklage Geräte/Möbel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva elettrodomestici/mobili"])},
  "Rücklage Wertpapiere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva titoli"])},
  "Rücklage Haus/Wohnung": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva casa/appartamento"])},
  "Rücklage Auto/Treibstoffe/Reparaturen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva auto/carburante/riparazioni"])},
  "Bewegung suchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca movimento"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["totale"])},
  "Sum of incomes/expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somma entrate/spese"])},
  "Showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra da"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["risultati"])},
  "Previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([">"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<"])},
  "Overviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramiche"])},
  "Attention: All data will be permanently deleted !!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione: tutti i dati verranno ELIMINATI DEFINITIVAMENTE!! È proprio sicuro di voler eliminare l'account?"])},
  "Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
  "Forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previsione"])},
  "Balance until today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo fino ad oggi"])},
  "Forecast by the end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previsioni entro la fine"])},
  "expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uscite"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto"])},
  "filter person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro persona"])},
  "Schweiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svizzera"])},
  "Österreich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
  "Deutschland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germania"])},
  "Anderes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
  "bis 18 Jahre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fino a 18 anni"])},
  "19 - 35 Jahre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19 - 35 anni"])},
  "36 - 65 Jahre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["36 - 65 anni"])},
  "über 65 Jahre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oltre i 65 anni"])},
  "5 and more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 e oltre"])},
  "3 and more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 e oltre"])},
  "Arbeiter/Arbeiterin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavoratore"])},
  "Angestellte/r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dipendente"])},
  "Führungskraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esecutivo"])},
  "Selbstständige/r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavoratore autonomo"])},
  "Unternehmer/in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprenditore"])},
  "Freiberufler/in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libero professionista"])},
  "Rentner/in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensionato"])},
  "Anderer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
  "starting value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore iniziale"])},
  "Haushalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilancio in famiglia"])},
  "Email has been successfully changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email è stata modificata con successo"])},
  "what is new?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Che cosa c'è di nuovo?"])},
  "remind me later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricordamelo più tardi"])},
  "understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho capito"])},
  "Important NOTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avviso importante"])},
  "QUESTION_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornendo un indirizzo sarà possibile ripristinare la Vostra password in caso la perdeste. L'indirizzo fornito sarà utilizzato esclusivamente per questo scopo e non sarà in alcun caso ceduto a terzi. Se non indicate un indirizzo, in caso di perdita di password non sarà possibile ripristinare l'accesso ai vostri dati, e sarà necessario creare un nuovo account, perdendo irrevocabilmente tutti i dati in precedenza inseriti."])},
  "Bitte geben Sie unter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È possibile indicare nelle"])},
  "Ihre E-Mailadresse ein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un indirizzo e-mail."])},
  "Ein Projekt von": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un progetto di"])},
  "copyRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizzato con i fondi Ministero sviluppo economico. Riparto 2020"])},
  "Reset password link sent on your email id.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimposta il link della password inviato sul tuo ID e-mail."])},
  "Password has been successfully changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password è stata modificata con successo"])},
  "User not found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente non trovato"])},
  "bargeld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contanti"])},
  "Banksaldo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto bancario"])},
  "show per page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mostra per pagina"])}
}